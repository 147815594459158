:root {
  font-family: "Source Serif Pro", serif;
  --backgroundcolor: white;
  --textcolor: black;
  --headerbackgroundcolor: #111827;
  --headerbackgroundtext: #e5e7eb;
}
body {
  margin: 0;
  padding: 0;
  background-color: var(--backgroundcolor);
}
section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: fit-content;
  overflow: hidden;
}
a:hover {
  color: var(--backgroundcolor);
  text-decoration: underline !important;
}
.lead {
  background-color: white !important;
  background: radial-gradient(at 64% 59%, #e34f6a 0, transparent 50%),
    radial-gradient(at 39% 30%, #fe897c 0, transparent 50%),
    radial-gradient(at 16% 50%, #f68dc5 0, transparent 50%),
    radial-gradient(at 51% 27%, #e84f59 0, transparent 50%),
    radial-gradient(at 62% 91%, #5cd5f0 0, transparent 50%),
    radial-gradient(at 19% 8%, #5defa3 0, transparent 50%),
    radial-gradient(at 91% 13%, #cdf193 0, transparent 50%);
  height: 90vh;
}
.lead button {
  background-color: var(--backgroundcolor) !important;
  color: var(--textcolor);
  padding: 0.75em 2.5em !important;
}
@media screen and (max-width: 700px) {
  .greeting .cycle-text {
    color: red !important;
    font-size: 3em !important;
  }
}
button {
  transition: 0.4s;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25em;
  cursor: pointer !important;
}
.center {
  display: flex;
  justify-content: center;
}
.show {
  display: flex;
}
.hide {
  display: none;
}
.disabled {
  pointer-events: none !important;
  color: #94a3b8 !important;
  background-color: #475569 !important;
}
.disabled a {
  pointer-events: none;
}
a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  z-index: 1;
}
.h-stack {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.button-1 {
  position: relative;
  border: 3px solid white;
  border-radius: 1000rem;
  color: white;
  font-size: 1.8em;
  padding: 0.2em 1.5em;
  font-family: "Basic-sans", sans-serif;
  background-color: rgba(255, 255, 255, 0.3);
}
button:hover {
  transform: scale(0.95);
}
p,
h1,
h2,
h3,
h4,
h5 {
  font-family: "Basic-sans", sans-serif;
  padding: 0;
  margin: 0;
}
nav {
  width: 100%;
  min-height: max-content;
  background-color: var(--headerbackgroundcolor);
  padding: 0em 0em;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
}
nav h1 {
  white-space: nowrap;
  padding: 0.75em;
  color: white;
  font-size: 2em;
  font-weight: 800;
  padding-left: 0.75em;
  color: var(--headerbackgroundtext);
  font-family: "Source Serif Pro" !important;
}
nav h5 {
  font-family: "Source Serif Pro" !important;
  color: white;
  font-size: 1.5em;
  color: var(--headerbackgroundtext);
  font-weight: 500;
}
nav a:hover {
  text-decoration: underline;
  color: var(--headerbackgroundtext);
}
.dropdown-wrapper {
  display: flex;
  z-index: 99999999;
  justify-content: center;
  align-items: center;
  gap: 1.25em;
  padding-right: 3rem;
}
.dropdown-toggle {
  font-size: 3em;
  margin-right: 0.25em;
  color: var(--headerbackgroundtext);
  display: none;
  z-index: 99999999;
}
@media screen and (max-width: 875px) {
  .dropdown-wrapper {
    display: none;
    z-index: 9999999999999999999999;
  }
  .show.dropdown-wrapper {
    display: flex !important;
    position: absolute;
    top: 100%;
    background-color: var(--headerbackgroundcolor) !important;
    right: 0;
    flex-direction: column;
    padding-bottom: 1em;
  }
  .show.dropdown-wrapper h5 {
    padding: 0.25em 0.5em;
    width: 5em;
    display: flex;
    padding-right: 0em;
    color: var(--textcolor);
    justify-content: flex-end !important;
  }
  .dropdown-toggle {
    display: flex;
  }
}

.welcomemessage-wrapper {
  padding: 1em 2em;
  font-size: 1.5em;
  font-family: "Basic-sans", sans-serif;
}
.welcomemessage-wrapper .greeting h1 {
  font-size: 4em;
  font-weight: 800;
  color: var(--backgroundcolor) !important;
}
.welcomemessage-wrapper .job-title h1 {
  font-size: 1.75em;
  font-weight: 100;
  color: var(--backgroundcolor) !important;
}
.project-info-wrapper {
  margin-bottom: auto;
  margin-left: auto;
  text-align: center;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  padding: 1em 1em;
  margin-bottom: 0;
}
.project-info-wrapper h2 {
  font-size: 2.75em;
  font-family: "Basic-sans", sans-serif;
}
.project-info-wrapper h3 {
  font-size: 2em;
  font-weight: 100;
  font-family: "Basic-sans", sans-serif;
}
.projectScreenshot {
  object-fit: cover;
  max-width: min(95%, 45em);
  max-height: 30em;
  border: none;
}
.quickfacts {
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  padding-bottom: 2em;
  justify-content: center;
}
.quickfacts ul {
  margin-bottom: auto;
  padding-left: 0.5em;
  max-width: 80% !important;
}
.quickfacts li {
  margin-top: 0.25em;
  margin-bottom: 0.25em;
  font-family: Basic-sans;
  font-size: 1.75em;
}
.logos-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-right: auto;
  justify-content: center;
  align-items: center;
  grid-gap: 1em;
  padding: 0;
  margin: 0;
  max-width: 90%;
}
.logos-wrapper img {
  object-fit: cover;
  height: 3em;
  background-color: rgba(255, 255, 255, 1);
  padding: 0.5em;
  border: 1px solid black;
  border-radius: 1em;
  transition: 0.2s;
}
.logos-wrapper img:hover {
  transform: scale(1.1);
}
.quickfacts .options-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 2em;
  grid-gap: 1em 1em;
}
.options-list.center {
  justify-content: center;
}
.photo-stack {
  width: 95% !important;
  justify-content: center;
  display: flex;
  justify-content: flex-start;
  gap: 0.75em;
  overflow-x: scroll;
  padding: 1em;
  padding-right: 2em;
  border-radius: 1em;
  margin-left: 3em;
  background-color: rgba(0, 0, 0, 0.25);
}
.photo-stack img {
  object-fit: cover;
  width: 36em;
  height: 28em;
  max-height: 90%;
  border-radius: 1em;
  max-width: 90vw;
}
.photography-section {
  position: relative;
}
.photo-frame {
  display: flex;
  flex-direction: column;
}
.photo-frame h5 {
  display: flex;
  margin-top: 0.33em;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 0.25em;
  font-size: 1.5em;
}
.photography-section .container,
.graphic-section .container {
  display: flex;
  width: 95%;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: flex-end;
  padding: 2em;
  max-width: 100%;
}
.photography-section .container .button-1 {
  font-size: 2em;
}
.back-icon {
  font-size: 3rem;
  margin-g: auto;
  color: var(--headerbackgroundtext) !important;
}
.back-icon:hover {
  transform: scale(0.95);
  color: var(--textcolor) !important;
}
.title-wrapper {
  display: flex;
  width: 95%;
  margin: 0 auto;
  justify-content: space-around;
  align-items: center;
  padding: 2em;
  padding-left: 0;
  padding-right: 0;
  color: var(--textcolor);
}
.title-wrapper h1 {
  margin: 0 auto;
  font-size: 3em;
}
.gallery-wrapper {
  width: 90%;
  margin: 0 auto;
  column-count: 2;
  gap: 0.25em;
  overflow: wrap;
}
@media screen and (max-width: 1000px) {
  .gallery-wrapper {
    column-count: 1;
  }
}

.gallery-wrapper img,
.gallery-wrapper .faux-photo {
  width: 100% !important;
  height: auto !important;
  object-fit: contain;
}
.graphic-gallery .graphic {
  align-items: center;
  background-color: #e2e8f0;
  display: flex;
  height: 12em;
  justify-content: center;
  object-fit: contain;
  padding: 1em;
  cursor: pointer;
  width: 20em;
}

.gallery-wrapper img:hover {
  cursor: pointer;
  user-select: none;
}
.slideshow-wrapper {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: var(--backgroundcolor);
  overflow: hidden;
  z-index: 9999999999;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: fixed;
}
.slideshow-wrapper .close-icon {
  left: 0;
  top: 0;
  margin: 0.5em 0.5em;
  z-index: 9999;
  font-size: 3rem;
  position: absolute;
  cursor: pointer;
  color: var(--textcolor);
}
.close-icon:hover {
  fill: red;
}
.slideshow-wrapper .forward-button {
  font-size: 7rem;
  padding-right: 0.2em;
  color: var(--textcolor);
}
.slideshow-wrapper .forward-area {
  width: 20%;
  height: 40em;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  z-index: 99;
}
.slideshow-wrapper .backward-button {
  font-size: 7rem;
  padding: 0;
  padding-left: 0.2em;
  color: var(--textcolor);
}
.slideshow-wrapper .backward-area {
  width: 20%;
  height: 40em;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 99;
}
.slideshow-wrapper img {
  width: min(75%, 50em);
  height: 40em !important;
  max-width: fit-content !important;
  aspect-ratio: 4/3;
  height: auto;
  margin: 0 auto;
  object-fit: contain;
  user-select: none;
}
.nav-img-wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.graphic-section {
  position: relative;
}
.graphic-section img {
  object-fit: contain;
  background-color: rgba(255, 255, 255, 0.3);
  padding: 0.75em;
  width: 25em;
  height: 25em;
  max-width: 70vw;
}
.graphic-gallery {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 1em;
  width: 100%;
  margin: 0 auto;
  grid-template-rows: 25em;
  grid-template-columns: repeat(auto-fill, minmax(25em, 1fr));
}
.footer-wrapper {
  background-color: var(--headerbackgroundcolor) !important;
  display: flex;
  color: var(--headerbackgroundtext) !important;
  padding: 1em 1em;
  align-items: center;
  margin: 0 !important;
}
.footer-wrapper {
  text-align: center;
  justify-content: center;
  max-width: 100%;
}
.footer-wrapper a {
  color: var(--headerbackgroundtext) !important;
}
.page-wrapper {
  margin: 0;
  overflow: hidden !important;
  min-height: 90vh !important;
  background-color: var(--backgroundcolor) !important;
  color: var(--textcolor) !important;
}
.error-link {
  background: hsla(276, 91%, 79%, 1);
  background: linear-gradient(
    90deg,
    hsla(276, 91%, 79%, 1) 0%,
    hsla(254, 74%, 65%, 1) 100%
  );
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.underline {
  text-decoration: underline;
}
.cycle-text {
  line-height: 1em;
}
.switch {
  width: 5em;
  border-radius: 10000em;
  height: 1.75em;
  background-color: #6d28d9;
  position: relative;
  display: flex;
  align-items: center;
  transition: 1s;
}
.on.switch {
  justify-content: flex-end;
}
.on.switch::after {
  content: "";
}
.icon-wrapper {
  display: flex;
  justify-content: center;
  font-size: 1.2em;
  align-items: center;
  background-color: #1e293b;
  position: absolute;
  width: 2.25em;
  height: 2.25em;
  color: white;
  border-radius: 100%;
}
.posts-wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 1em;
  width: 50em !important;
  max-width: 90%;
  margin: 0 auto;
  justify-content: flex-start;
  padding: 1em;
  align-items: center;
}
.posts-wrapper a {
  width: 95%;
}
.post {
  background-color: var(--headerbackgroundtext);
  height: fit-content;
  color: var(--headerbackgroundcolor);
  border-radius: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  box-shadow: 0px 10px 100px rgba(255, 255, 255, 30%);
  padding-bottom: 1em;
}
.post h1,
.post h2,
.post h5,
.post p {
  padding: 0 0.5em;
  width: 95%;
  font-family: "Source Serif Pro", serif !important;
}
.post h1 {
  font-size: 1.75em;
  margin-top: 0.3em;
  font-weight: 900;
}
.post h2 {
  margin-top: 1em;
  font-size: 1.2em;
  font-weight: 400;
}
.post h5 {
  margin-top: 0.25em;
  font-size: 1em;
}
.post p {
  font-size: 1.25em;
}
.post img {
  max-height: 25em;
  min-width: 100%;
  object-fit: cover;
  width: 100%;
  bottom: 10;
  top: 0;
  border-radius: 1em 1em 0em 0em;
}
.blog-editor-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: 1em 1em;
  max-width: min(100%, 800px);
  grid-gap: 1em 0em;
}
.blog-editor-wrapper label {
  font-size: 1.2em;
  margin-right: auto;
  color: var(--headerbackgroundtext);
}
.blog-editor-wrapper input,
.blog-editor-wrapper textarea {
  border: none;
  width: 100%;
  font-size: 1.5em;
  border-radius: 0.45em;
  padding: 0.25em 0.25em;
  padding-right: 0;
  font-family: "Source Serif Pro", serif !important;
  background-color: #e2e8f0;
}
.blog-editor-wrapper textarea {
  height: 7em;
}
.blog-editor-wrapper a:hover {
  color: rgb(233, 30, 99);
}
.form-item {
  width: 100%;
}
textarea.big {
  height: 100vh;
}
.cool-purple-button {
  padding: 0.25em 2em;
  font-size: 1.75em;
  font-weight: 900;
  background-color: #6d28d9;
  margin-left: auto;
  border-radius: 0.33em;
  color: white;
  border: none;
  box-shadow: -3px 3px 30px 10px rgba(109, 40, 217, 0.5);
  font-family: "Source Serif Pro", serif !important;
}
.cool-purple-button:hover {
  transform: scale(0.9);
}
.blog-editor-wrapper img {
  object-fit: cover;
  width: 100%;
  height: 30em;
  border-radius: 1em;
  margin-bottom: 1em;
}
.login-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: fit-content;
  margin: 0 auto;
  width: 90%;
  margin-top: 0.5em;
  max-width: 20em;
  color: var(--textcolor);
}
.login-wrapper .cool-purple-button {
  margin: 0;
  margin-top: 0.3em;
  margin-left: auto;
  font-size: 1.4em;
}
.login-wrapper input {
  width: 100%;
  border: none;
  border-radius: 0.25em;
  font-family: "Source Serif Pro", serif !important;
  font-size: 1.5em;
  background-color: var(--headerbackgroundcolor);
  color: var(--headerbackgroundtext);
  padding: 0.25em;
}
input.invalid {
  border: 2px solid #dc2626 !important;
}
.login-wrapper .h-stack {
  display: flex;
  width: 100%;
  flex-direction: row;
}
.check,
.cross {
  font-size: 2em;
  color: #22c55e;
}
.cross {
  color: #dc2626;
}

.post-page-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  background-color: var(--backgroundcolor);
}
.post-page-wrapper h1,
.post-page-wrapper h2,
.post-page-wrapper p {
  color: var(--headerbackgroundtext) !important;
  width: min(90%, 70em) !important;
  max-width: 100% !important;
  overflow-x: hidden !important;
  padding: 0 !important;
  font-family: "Source Serif Pro", serif !important;
}
.post-page-wrapper h1 {
  text-align: center;
  margin-top: 0.75em;
  padding: 0em 1em;
}
.post-page-wrapper h2 {
  font-weight: 100;
  text-align: center;
  font-size: 1.25em;
  margin-bottom: 0.75em;
}
.post-page-wrapper img {
  object-fit: contain;
  width: 100%;
  max-width: min(90%, 50em);
  border-radius: 2em;
  border: 3px solid var(--textcolor) !important;
}
.post-page-wrapper p {
  font-size: 1.2em;
  margin: 0 auto;
  margin-top: 1em;
  text-indent: 2em;
  line-height: 1.5em;
}
.post-page-wrapper a {
  color: rgb(233, 30, 99);
}
.post-page-wrapper .content-wrapper {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25em;
  margin-left: auto;
}
.post-page-wrapper li {
  font-size: 1.25em;
}
.post-page-wrapper ul {
  margin: 0 auto;
}
.tags-wrapper {
  margin-left: 0.75em;
  display: flex;
  grid-gap: 0.5em;
  flex-wrap: wrap;
  margin-top: 0.5em;
  margin-right: auto;
  font-size: 1em;
}
.tag {
  padding: 0.25em 0.75em;
  border-radius: 100em;
  background-color: #ea580c;
}
.comment-wrapper {
  display: flex;
  color: var(--textcolor);
  flex-direction: column;
  align-items: center !important;
  justify-content: center !important;
  padding-bottom: 1em;
  margin: 1em;
  gap: 1em;
}
.comment-wrapper h1 {
  color: var(--textcolor);
  margin: 0;
  padding: 0.25em;
  font-family: "Source Serif Pro", serif !important;
}
.comment-wrapper textarea {
  width: 98%;
  resize: none;
  margin: 0;
  font-size: 1.5em;
  padding: 0.25em 0.5em;
  height: 5em;
  border-radius: 0.5em;
  z-index: 9999;

  font-family: "Source Serif Pro", serif !important;
}
.comment-wrapper .cool-purple-button {
  font-size: 1.5em;
  margin-top: 0.25em;
}
.post-comment-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  max-width: 90%;
  width: 60em;
}
.post-comment-wrapper h5 {
  color: var(--textcolor);
  font-size: 1.2em;
  display: flex;
  gap: 0.25em;
  align-items: center;
  justify-content: center;
  font-family: "Source Serif Pro", serif !important;
}
hr {
  border-top: 1px solid var(--textcolor);
  margin-top: 1em;
  width: 90%;
}
.comment {
  width: 100%;
  border: 1px solid var(--textcolor);
  padding: 0.25em 0.5em;
  max-width: 90%;
  width: 60em;
}
.comment p {
  font-family: "Source Serif Pro", serif !important;
  font-size: 1.33em;
  text-align: left !important;
  width: 100%;
}
.comment .user-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.25em;
  margin-bottom: 0.25em;
}
.comment .user-info .icon {
  font-size: 1.5em;
}
.comment .user-info h5 {
  font-size: 1.25em;
  font-family: "Source Serif Pro", serif !important;
}
.alert-wrapper {
  position: fixed;
  top: 0;
  margin-top: 1.5em;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  border-radius: 1em;
  background-color: var(--backgroundcolor);
  box-shadow: 0em 0em 2em 0 var(--headerbackgroundtext);
  max-width: max-content;
  z-index: 99999999999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.33em;
  padding-bottom: 1.2em !important;
  width: 30em;
  max-width: 90% !important;
  color: var(--headerbackgroundtext);
  align-items: center;
}
.alert-wrapper h1 {
  padding: 0.25em 1em;
  padding-bottom: 0;
  color: var(--textcolor) !important;
}
.alert-wrapper p {
  text-align: center;
  font-size: 1.25em;
  max-width: 90%;
  margin: 0 auto;
  color: var(--textcolor) !important;
}
.alert-wrapper .options-wrapper {
  display: flex;
  justify-content: center;
  gap: 1em;
  width: 90%;
}
.alert-wrapper .options-wrapper button {
  padding: 0.25em 1em;
  max-width: 12em;
  font-family: "Source Serif Pro", serif !important;
  font-size: 1.3em;
  border: none;
  width: 100% !important;
  border-radius: 0.25em;
  margin: 0 auto;
  margin-top: 0.5em;
}
.code-block {
  width: 40em;
  height: 20em;
  border-radius: 2em;
  display: flex;
  padding: 0.5em 1.5em;
  background-color: #1e293b;
  font-family: "Courier New", Courier, monospace;
  color: white;
  flex-direction: column;
  max-width: 90%;
}
.line {
  overflow: scroll;
  white-space: nowrap;
  font-size: 1.5em;
}
.function {
  color: #ef4444 !important;
}
.parentheses {
  color: blue !important;
}
.curly-bracket {
  color: purple;
}
.function_name {
  color: #22c55e !important;
}
.indent {
  text-indent: 1em;
}
.variable-identifier {
  color: lawngreen;
}
.variable-name {
  color: slateblue;
}
.string {
  color: violet !important;
}
.button-1:after {
  content: "";
  display: block;
  position: absolute;
  width: 20%;
  height: 100%;
  white-space: nowrap;
  background-color: none;
  bottom: 0;
  border-radius: 1000rem;
  left: 0;
  transition: 1s;
  z-index: -99;
}
.button-1:hover,
.button-1:focus {
  color: black !important;
}
.button-1:hover:after,
.button-1:focus:after {
  width: 100%;
  background-color: white;
}
.scrolljumper-container h1 {
  transition: 1s;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999999;
  cursor: pointer;
  margin-right: auto;
  flex: 1;
  padding: 0 2em;
  padding-right: 0.5em;
}

.scrolljumper-container h1:not(.active) {
  margin-left: 1em;
}
.content-wrapper span.codeblock{
  padding: .2em 1.75em;
  font-family: monospace;
  background-color: #d4d4d4;
  text-indent: 0em ;

}
.content-wrapper img.subimage {
  max-width: 45%;
}